exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-basic-category-js": () => import("./../../../src/templates/basic/category.js" /* webpackChunkName: "component---src-templates-basic-category-js" */),
  "component---src-templates-basic-compilation-js": () => import("./../../../src/templates/basic/compilation.js" /* webpackChunkName: "component---src-templates-basic-compilation-js" */),
  "component---src-templates-basic-compilations-js": () => import("./../../../src/templates/basic/compilations.js" /* webpackChunkName: "component---src-templates-basic-compilations-js" */),
  "component---src-templates-basic-home-js": () => import("./../../../src/templates/basic/home.js" /* webpackChunkName: "component---src-templates-basic-home-js" */),
  "component---src-templates-basic-object-js": () => import("./../../../src/templates/basic/object.js" /* webpackChunkName: "component---src-templates-basic-object-js" */),
  "component---src-templates-basic-policy-js": () => import("./../../../src/templates/basic/policy.js" /* webpackChunkName: "component---src-templates-basic-policy-js" */)
}

